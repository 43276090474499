import { baseRequest } from '@/API'
export function getDataInerfaceList(params) {
    //获取接口列表
    return baseRequest({
        url: '/interface/openInterface/getInterfaceMarket',
        method: 'post',
        data: params
    })
}
export function getInterfaceCollectedList(params) {
    //获取接口收藏列表
    return baseRequest({
        url: '/usercenter/opencollect/selectinterfaceid',
        method: 'post',
        data: params
    })
}

export function collectInterface(params) {
    //接口收藏
    return baseRequest({
        url: '/usercenter/opencollect/data',
        method: 'post',
        data: params
    })
}
export function getHasAppliedInterfaces() {
    //获取用户已经申请的接口列表
    return baseRequest({
        url: '/interface/openInterface/getAppliedInterfaces',
        method: 'post'
    })
}
export function getInterfaceApplied(params) {
    //获取数据集接口申请状态
    return baseRequest({
        url: '/interface/openInterface/getInterfaceApplied',
        method: 'post',
        params
    })
}

export function cancelCollectInterface(params) {
    return baseRequest({
        url: '/usercenter/opencollect/del',
        method: 'post',
        params
    })
}
export function getDepartmentList() {
    return baseRequest({
        url: '/interface/openInterface/getInterCountByOffice',
        method: 'post'
    })
}
export function getInterfaceInfo(params) {
    //接口详情
    return baseRequest({
        url: '/interface/openInterface/info',
        method: 'post',
        params
    })
}
export function getAllSelectOptions(params) {
    return baseRequest({
        url: '/catalog/sysdict/selectAll',
        method: 'post',
        params
    })
}

export function getRelateAppList(params) {
    return baseRequest({
        url: '/application/open/page',
        method: 'post',
        data: params
    })
}
export function applyDataInterface(params) {
    //提交接口申请
    return baseRequest({
        url: '/interface/openservice/datasubmit',
        method: 'post',
        data: params
    })
}
export function getApplicationDetail(params) {
    //开放门户我的申请详情查询
    return baseRequest({
        url: '/interface/openservice/myapplydetail',
        method: 'post',
        data: params
    })
}

// 接口服务左边部门接口数量树
export function getInterCountByOffice(){
    return baseRequest({
        url: '/interface/openInterface/getInterCountByOffice',
        method: 'post'
    })
}
